import { Interpolation, Theme } from '@emotion/react';
import { Link } from 'gatsby';
import React from 'react';
import useButtonStates from '../../hooks/useButtonStates';
import tw from 'twin.macro';

interface Props {
  title: string
  target?: string
  to: string
  disabled?: boolean
  css?: Interpolation<Theme>
}

const InternalTextLink: React.FC<Props> = props => {
  const { title, target, to, disabled, css } = props
  const {
    handleFocus,
    handleBlur,
    focus
  } = useButtonStates()

  return (
<Link className="group" onFocus={handleFocus} onBlur={handleBlur} css={[tw`outline-none p-2 -ml-2 self-start hover:underline`, focus && tw`underline border border-dotted border-blue-250 mt-[-1px]`, css, disabled && tw`pointer-events-none`]} target={target} title={title} to={to}>
  <span css={[tw`text-blue-250 group-hover:text-blue-300 group-active:underline`, focus && tw`ml-[-1px]`, disabled && tw`opacity-40`]}>{title}</span>
    </Link>
  )
}

export default InternalTextLink