import { Link } from "gatsby"
import React, { useState, useContext, useEffect } from "react"
import { Restaurant } from "../../templates/dineshop"
import tw from "twin.macro"
import Button from "../Button/Button"
import Mapplic from "../Mapplic/Mapplic"
import TranslateClient from "../../clients/TranslateClient"
import { getStringValuesArray, mapValuesToObject } from "../../helpers"
import { LanguageContext } from "../../context/Language"
import BackButton from "./BackButton"
import InternalTextLink from "./InternalTextLink"
import PdfLink from "../Link/PdfLink"
import { Image } from "../Image"

interface Props {
  restaurant: Restaurant
}

const RestaurantDetail: React.FC<Props> = ({ restaurant }) => {
  const viewMenuLinkType = restaurant?.restaurantPost?.viewMenuButtonType
  const downloadMenuLink =
    restaurant?.restaurantPost?.viewMenuDownload?.localFile?.publicURL
  const viewMenuLinkUrl = restaurant.restaurantPost?.viewMenuLink?.url

  const backText = "Back"
  const hoursText = "Hours"
  const locationText = "Location"
  const title = restaurant.title
  const { highlightText, description, locationTitle } =
    restaurant.restaurantPost
  const viewMenuText = restaurant?.restaurantPost?.viewMenuLink?.title
    ? restaurant?.restaurantPost?.viewMenuLink?.title
    : "View Menu"
  const orderText = restaurant.restaurantPost?.orderForPickupLink?.title
    ? restaurant.restaurantPost?.orderForPickupLink?.title
    : "ORDER NOW FOR PICKUP"
  const [text, setText] = useState({
    backText,
    hoursText,
    locationText,
    viewMenuText,
    title,
    highlightText,
    description,
    locationTitle,
  })
  const { language } = useContext(LanguageContext)

  useEffect(() => {
    if (language === "en") {
      setText({
        backText,
        hoursText,
        viewMenuText,
        locationText,
        title,
        highlightText,
        description,
        locationTitle,
      })
    } else {
      ;(async () => {
        const textToTranslate = getStringValuesArray([
          backText,
          hoursText,
          viewMenuText,
          locationText,
          title,
          highlightText,
          description,
          locationTitle,
        ])
        const translations = await TranslateClient.translate(
          textToTranslate,
          language
        )
        const translated = mapValuesToObject(text, translations)
        setText(translated)
      })()
    }
  }, [language])

  return (
    <article css={[tw`px-8 max-w-6xl mx-auto`]}>
      <BackButton />
      <h1
        dangerouslySetInnerHTML={{ __html: text?.title }}
        css={[
          tw`text-3xl sm:text-4xl font-extrabold mb-6 text-center sm:text-left`,
        ]}
      />
      <div
        css={[
          tw`sm:hidden max-w-[12rem] max-h-[10rem] self-center md:pt-0 mx-auto mb-8`,
        ]}
      >
        <Image
          src={
            restaurant?.restaurantPost?.thumbnailImage?.localFile
              ?.childImageSharp?.gatsbyImageData
          }
          publicURL={
            restaurant?.restaurantPost?.thumbnailImage?.localFile?.publicURL
          }
          alt=""
          role="presentation"
        />
      </div>
      {restaurant?.restaurantPost?.orderForPickupLink?.url && (
        <Button
          styles={[tw`h-12 ml-0 sm:hidden mx-auto mb-8`]}
          ariaLabel="order now"
          link={restaurant.restaurantPost?.orderForPickupLink?.url}
          text={restaurant.restaurantPost?.orderForPickupLink?.title}
        />
      )}
      <div css={[tw`flex flex-col sm:flex-row justify-evenly sm:gap-12`]}>
        <div css={[tw`flex-1`]}>
          <Image
            src={
              restaurant?.featuredImage?.node?.localFile?.childImageSharp
                ?.gatsbyImageData
            }
            publicURL={restaurant?.featuredImage?.node?.localFile?.publicURL}
            alt=""
          />
        </div>
        <div css={[tw`flex flex-col flex-1 gap-6`]}>
          <div
            css={[
              tw`hidden sm:block max-w-[12rem] max-h-[10rem] self-center pt-8 md:pt-0`,
            ]}
          >
            <Image
              src={
                restaurant?.restaurantPost?.thumbnailImage?.localFile
                  ?.childImageSharp?.gatsbyImageData
              }
              publicURL={
                restaurant?.restaurantPost?.thumbnailImage?.localFile?.publicURL
              }
              alt=""
              role="presentation"
            />
          </div>
          <div>
            <h2
              css={[tw`text-xl mt-8 sm:mt-0`]}
              dangerouslySetInnerHTML={{
                __html: text?.highlightText,
              }}
            />
            <p
              dangerouslySetInnerHTML={{
                __html: text?.description,
              }}
            />
          </div>
          {viewMenuLinkType === "download" && downloadMenuLink && (
            <PdfLink
              title="View Menu"
              target={"_blank"}
              to={
                restaurant?.restaurantPost?.viewMenuDownload?.localFile
                  ?.publicURL
              }
            />
          )}
          {viewMenuLinkType === "link" && viewMenuLinkUrl && (
            <InternalTextLink
              to={restaurant.restaurantPost?.viewMenuLink?.url}
              target={restaurant.restaurantPost?.viewMenuLink?.target}
              title={text?.viewMenuText}
            />
          )}

          <div css={[tw`flex flex-col sm:flex-row justify-evenly`]}>
            <div css={[tw`w-full`]}>
              <p css={[tw`text-xl`]}>{text?.hoursText}</p>
              <ul css={[]}>
                {restaurant.restaurantPost?.hoursOfOperation?.map(
                  (item, index) => {
                    let hours = `${item.open ? item.open : ""} - ${item.close ? item.close : ""} (${item.day ? item.day : ""})`
                    return (
                      <li
                        key={index}
                        dangerouslySetInnerHTML={{ __html: hours }}
                      />
                    )
                  }
                )}
              </ul>
            </div>
            <div css={[tw`w-full mt-4 sm:mt-0`]}>
              <p css={[tw`text-xl`]}>{text?.locationText}</p>
              <p
                dangerouslySetInnerHTML={{
                  __html: text?.locationTitle,
                }}
              />
            </div>
          </div>
          {restaurant?.restaurantPost?.orderForPickupLink?.url && (
            <Button
              text={orderText}
              styles={[tw`h-12 ml-0 hidden sm:flex`]}
              ariaLabel="order now"
              link={restaurant.restaurantPost?.orderForPickupLink?.url}
            />
          )}
        </div>
      </div>
      <div css={[tw`border border-lightGray mt-16`]}>
        <Mapplic
          height="300"
          focusOnLoad={false}
          location={restaurant.restaurantPost?.locationMapIdentifier}
          sidebar={false}
          search={false}
        />
      </div>
    </article>
  )
}

export default RestaurantDetail
