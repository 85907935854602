import React from "react"
import tw from "twin.macro"
import { graphql, PageProps } from "gatsby"
import { Restaurant } from "../dineshop"
import RestaurantDetail from "../../components/DineAndShop/RestaurantDetail"
import { SEO } from "../../components/SEO"

const RestaurantPage: React.FC<PageProps<any>> = ({ data, location }) => {
  const restaurant: Restaurant = data.wpRestaurant
  const title = data?.wpRestaurant?.title

  return (
    <>
      <SEO url={location.href} title={title} />
      <section
        css={[
          tw`w-full bg-contain bg-top pt-20 min-h-[40rem]`,
          {
            backgroundImage: "url('/airport-interior.png')",
            backgroundRepeat: "no-repeat",
          },
        ]}
      >
        <RestaurantDetail restaurant={restaurant} />
      </section>
    </>
  )
}

export const query = graphql`
  query ($id: String) {
    wpRestaurant(id: { eq: $id }) {
      title
      id
      slug
      nodeType
      featuredImage {
        node {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      restaurantPost {
        description
        highlightText
        locationMapIdentifier
        locationTitle
        hoursOfOperation {
          close
          day
          open
        }
        orderForPickupLink {
          target
          title
          url
        }
        viewMenuButtonType
        viewMenuDownload {
          localFile {
            publicURL
          }
        }
        viewMenuLink {
          title
          url
          target
        }
        thumbnailImage {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`

export default RestaurantPage
